import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

import commonStyles, {
  customCssVars as commonCssVars,
} from 'Groups/stylesParams';
import { groupsListWidgetStylesParams } from 'settings/groups-list-widget';
import { getGroupsListWidgetCssVars } from 'settings/groups-list-widget/vars';

export const WIDGET_MAX_SPACING = 100;

const stylesParams = {
  sideBySideCardSpacing: createStylesParam('sideBySideCardSpacing', {
    getDefaultValue: () => 12,
    type: StyleParamType.Number,
  }),
};

export default {
  ...commonStyles,
  ...groupsListWidgetStylesParams,
  ...stylesParams,
};

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = (params) => {
  return {
    ...commonCssVars(params),
    ...getGroupsListWidgetCssVars(params),
  };
};
